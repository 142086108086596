:root {
  /* --altcha-border-width: 1px;
  --altcha-border-radius: 3px;
  --altcha-color-base: #ffffff; */
  --altcha-color-border: theme(colors.primary.700);
  --altcha-color-text: theme(colors.primary.700);
  --altcha-color-border-focus: theme(colors.primary.700);
  --altcha-color-error-text: theme(colors.red.500);
  /* --altcha-color-footer-bg: #f4f4f4;
  --altcha-max-width: 260px; */
}

div.altcha-checkbox > input[type="checkbox"] {
  @apply bg-primary-50;
  @apply border-primary-700;
  @apply hover:bg-primary-200;
  @apply cursor-pointer;
}

div.altcha-checkbox > input[type="checkbox"]:checked {
  @apply bg-primary-700;
  @apply border-primary-700;
}
