.blog-article h2 {
    @apply lg:text-2xl text-xl font-bold mb-4 mt-16;
}

.blog-article h3 {
    @apply lg:text-xl text-lg font-bold mb-2 mt-8;
}

.blog-article h4 {
    @apply lg:text-lg text-base font-bold;
}

.blog-article h1,
.blog-article h2,
.blog-article h3,
.blog-article h4,
.blog-article h5,
.blog-article h6 {
    @apply text-gray-800 tracking-wide;
}

.blog-article p,
.blog-article a,
.blog-article span,
.blog-article i,
.blog-article b,
.blog-article strong,
.blog-article dt,
.blog-article dd,
.blog-article li {
    @apply !text-base;
}

.blog-article p,
.blog-article span,
.blog-article i,
.blog-article dt,
.blog-article dd,
.blog-article li {
    @apply text-gray-600;
}

.blog-article a {
    @apply text-primary-600 underline font-medium;
}

.blog-article b,
.blog-article strong {
    @apply font-bold text-gray-800;
}

.blog-article ul {
    @apply list-disc ml-8 mb-6 space-y-1 marker:text-gray-600;
}

.blog-article li,
.blog-article p {
    @apply leading-loose;
}
.blog-article p {
    @apply max-md:text-balance max-md:text-justify max-md:hyphens-auto;
}

.blog-article p {
    @apply mb-4;
}

.blog-article img {
    @apply w-full h-auto rounded-xl border;
}
