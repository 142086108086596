* {
    font-family: "Montserrat", sans-serif;
}

@font-face {
    font-family: "Montserrat";
    src: url("/assets/fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/assets/fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/assets/fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("/assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

p,
i,
b,
dt,
dd,
li {
    @apply sm:text-base text-sm;
}

p,
i,
dt,
dd,
li {
    @apply text-gray-500;
}

input,
textarea {
    @apply text-sm !important;
    @apply text-gray-500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply text-gray-900;
}
