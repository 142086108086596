.tiptap.ProseMirror {
    height: 100%;
}

.tiptap.ProseMirror-focused {
    outline: none;
}

.tiptap ul {
    margin-left: 1em;
}

.tiptap ul li {
    display: list-item;
    list-style-type: circle;
}

.tiptap p {
    margin-top: 16px;
    margin-bottom: 16px;
}

.tiptap p:empty {
    margin: 0;
}

.tiptap p:has(> br.ProseMirror-trailingBreak:only-child) {
    margin: 0;
}
