.text-hero {
    @apply 2xl:text-[1.1rem] text-base text-gray-600 font-sans 2xl:leading-9 leading-8;
}

.text-landing {
    @apply text-sm font-sans antialiased leading-6;
}

b.text-landing {
    @apply font-semibold;
}

/* carousel */

.react-multi-carousel-dot-list {
    @apply flex gap-2;
}
.react-multi-carousel-dot button {
    @apply !bg-gray-300 !border-gray-300 scale-125;
}

.react-multi-carousel-dot--active button {
    @apply !bg-primary-600 !border-primary-600 scale-125;
}
