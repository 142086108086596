.step {
    @apply relative z-[42];
}

.step::before {
    @apply absolute -top-5 -left-5 -right-5 -bottom-5 bg-white z-[-1] pointer-events-none rounded-xl;
    content: "";
}

#tourContainer b {
    @apply text-primary-600;
}
