.fc {
    @apply text-sm font-light;
}

.fc .fc-toolbar .fc-toolbar-chunk {
    @apply mt-2 place-self-center !important;
}

.fc .fc-list-event-title {
    @apply text-sm text-gray-600;
}

.fc-toolbar-title {
    @apply font-medium max-md:bg-none [@media(max-width:74rem)]:!text-lg;
}

.fc .fc-list-empty {
    @apply bg-primary-25 !important;
}

.fc .fc-dayGridMonth-button {
    @apply hidden md:inline-block !important;
}

.fc-prev-button,
.fc-next-button {
    @apply p-1 !important;
}

.fc-prev-button span,
.fc-next-button span {
    @apply text-white;
}

.fc .fc-listWeek-button {
    @apply rounded-r md:rounded-none !important;
}

.fc .fc-header-toolbar {
    @apply [@media(max-width:74rem)]:grid gap-4 !px-6;
}

.fc .fc-header-toolbar .fc-toolbar-chunk {
    @apply [@media(max-width:74rem)]:!self-start [@media(max-width:74rem)]:mr-auto;
}

.fc .fc-list-empty-cushion {
    @apply text-sm;
}

.fc button {
    @apply bg-primary-600 border-primary-600 md:text-sm text-xs p-1 md:px-2 md:py-2 !important;
}

.fc .fc-daygrid-block-event {
    @apply text-white !important;
}

.fc .fc-daygrid-block-event .fc-event-time {
    @apply hidden;
}

.fc .fc-daygrid-block-event .fc-event-title {
    @apply font-light ml-2 !important;
}

.fc .fc-list {
    @apply border-gray-200 !important;
}

.fc-daygrid-day-top a {
    @apply text-gray-400 text-xs;
}

.fc .fc-daygrid-event {
    @apply text-xs;
}

.fc-daygrid-week-number {
    @apply bg-primary-600 text-xs text-white rounded-lg px-1.5 ml-1 mt-0.5 !important;
}

button.fc-prev-button,
button.fc-next-button {
    padding: 7px !important;
}

.fc .fc-list-event-title a {
    /* @apply text-gray-700  text-sm font-medium !important; */
}

.fc .fc-list-event-dot,
.fc-daygrid-event-dot {
    @apply border-primary-500 !important;
}

.fc-daygrid-event-dot {
    @apply bg-primary-500;
}

.fc-col-header-cell,
.fc-list-day-cushion {
    @apply text-gray-800 border-0 !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
    @apply border-gray-200 border !important;
}

.fc-day-today {
    @apply bg-primary-50 !important;
}

.fc-day-today .fc-daygrid-day-top a {
    @apply text-primary-700 !important;
}

.fc-theme-standard th {
    @apply border-0 !important;
}

.fc-scrollgrid,
.fc-list-table {
    @apply border-0 !important;
}

.fc-list-table {
    @apply text-sm !important;
}

.fc-listDay-view,
.fc-listWeek-view {
    @apply border-0 !important;
}

.fc-list-event {
    @apply divide-y !important;
}

.fc .fc-list-event-time {
    @apply border-b-0 !important;
}

.fc .fc-list-event-time {
    @apply pb-6 !important;
}

/* Day headings */
.fc-list-day-cushion.fc-cell-shaded a {
    @apply text-lg font-bold text-primary-500 mt-3 !important;
}

.fc .fc-list-table td,
.fc .fc-list-day-cushion {
    @apply !pb-2 !py-2;
}

tr.fc-list-event td {
    @apply !p-0 !pl-4;
}

/* Timegrid */

.fc-timegrid-axis-cushion {
    @apply text-[0.5625rem];
}

.fc-timegrid-slot-label-cushion {
    @apply text-xs text-gray-500;
}

.fc-event-time {
    @apply md:!text-xs text-[0.625rem];
}

.fc-timegrid-event {
    @apply rounded-lg p-2;
}

.fc-timegrid-event .fc-event-title {
    @apply md:!text-sm !text-xs truncate;
}

/* End Timegrid */

/* Fixes resizing issue: https://github.com/fullcalendar/fullcalendar/issues/6407 */
.fc-col-header,
.fc-daygrid-body,
.fc-scrollgrid-sync-table,
.fc-timegrid-body,
.fc-timegrid-body table {
    width: 100% !important;
}

.daygrid :last-child > div:first-child > div:first-child {
    border-right-width: 1px;
}

/* Header */

.fc-scrollgrid-sync-inner a.fc-col-header-cell-cushion {
    @apply w-full;
}

/* Day number */
.fc-daygrid-day-number {
    @apply !text-xs !text-gray-700 font-medium;
}

.fc-timegrid-slot,
.fc-daygrid-body {
    cursor: pointer;
}

.fc-header-toolbar {
    @apply !px-4 lg:!px-0;
}

div.fc-header-toolbar {
    @apply !px-4 lg:!px-6;
}

.fc-event-main {
    @apply !p-0;
}

.fc-event {
    @apply !p-0;
}

.fc .fc-next-button,
.fc .fc-prev-button {
    @apply !border-white;
}

/* Day Agenda / List */

.fc-list-event-graphic {
    @apply hidden;
}

.fc .fc-button {
    @apply !p-1.5 [@media(min-width:74rem)]:!py-2 [@media(min-width:74rem)]:!px-3;
}

.fc .fc-list-event {
    @apply cursor-pointer;
}

.fc .fc-listWeek-button {
    @apply !rounded;
}

/* Selected Event */
.fc .highlighted-event {
    @apply animate-pulse shadow-md;
}
